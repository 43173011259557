import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const ProductsArquivos = () => {
  const data = useStaticQuery(graphql`
    query {
      arquivo04: file(
        relativePath: { eq: "products/arquivo-OfJr-04-gavetas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arquivo03: file(
        relativePath: { eq: "products/arquivo-of3-jr-03gavetas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arquivo02: file(relativePath: { eq: "products/Arquivo-OF2-JR.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo02.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F2 JR 02 Gavetas - Produndidade 50 cm</h3>

          <ul>
            <li>Cód.: OF2 50/26</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 17kg</li>
            <li>Altura: 70 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 50 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 438,00</li>
            <li>Chapa 24 R$ 550,00</li>
            <li>Chapa 22 R$ 648,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 503,00</li>
            <li>Chapa 24 R$ 617,00</li>
            <li>Chapa 22 R$ 722,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 438,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo02.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F2 JR 02 Gavetas - Produndidade 70 cm</h3>

          <ul>
            <li>Cód.: OF2 50/26</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 18kg</li>
            <li>Altura: 70 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 70 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 536,00</li>
            <li>Chapa 24 R$ 646,00</li>
            <li>Chapa 22 R$ 750,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 606,00</li>
            <li>Chapa 24 R$ 720,00</li>
            <li>Chapa 22 R$ 825,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 536,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo03.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F3 JR 03 Gavetas - Produndidade 50 cm</h3>

          <ul>
            <li>Cód.: OF3 JR</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 19kg</li>
            <li>Altura: 103 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 50 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 564,00</li>
            <li>Chapa 24 R$ 662,00</li>
            <li>Chapa 22 R$ 767,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 667,00</li>
            <li>Chapa 24 R$ 768,00</li>
            <li>Chapa 22 R$ 874,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 564,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo03.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F3 JR 03 Gavetas - Produndidade 70 cm</h3>

          <ul>
            <li>Cód.: OF3 70/26</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 19kg</li>
            <li>Altura: 103 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 70 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 664,00</li>
            <li>Chapa 24 R$ 763,00</li>
            <li>Chapa 22 R$ 864,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 770,00</li>
            <li>Chapa 24 R$ 870,00</li>
            <li>Chapa 22 R$ 976,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 664,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo04.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F4 JR 04 Gavetas - Produndidade 50 cm</h3>

          <ul>
            <li>Cód.: OFJR 50</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 21kg</li>
            <li>Altura: 133 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 50 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 678,00</li>
            <li>Chapa 24 R$ 781,00</li>
            <li>Chapa 22 R$ 880,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 789,00</li>
            <li>Chapa 24 R$ 895,00</li>
            <li>Chapa 22 R$ 998,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 678,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo04.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F4 JR 04 Gavetas - Produndidade 60 cm</h3>

          <ul>
            <li>Cód.: OFJR 60</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 21kg</li>
            <li>Altura: 133 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 60 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 729,00</li>
            <li>Chapa 24 R$ 828,00</li>
            <li>Chapa 22 R$ 932,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 854,00</li>
            <li>Chapa 24 R$ 958,00</li>
            <li>Chapa 22 R$ 1.065,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 729,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.arquivo04.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Arquivo 0F4 JR 04 Gavetas - Produndidade 70 cm</h3>

          <ul>
            <li>Cód.: OFJR 70</li>
            <li>Chapa: 26</li>
            <li>3 Gavetas com rolamento</li>
            <li>Pintura: a pó eletrostática texturizada</li>
            <li>Peso: 23kg</li>
            <li>Altura: 133 cm </li>
            <li>Largura: 46 cm</li>
            <li>Profundidade: 70 cm</li>
            <br />
            {/* <li>--- Com rolamentos</li>
            <li>Chapa 26 R$ 781,00</li>
            <li>Chapa 24 R$ 882,00</li>
            <li>Chapa 22 R$ 981,00</li>
            <br />
            <li>--- Com microesféras (Telescópio)</li>
            <li>Chapa 26 R$ 900,00</li>
            <li>Chapa 24 R$ 1.003,00</li>
            <li>Chapa 22 R$ 1.106,00</li> */}
          </ul>

          {/* <p className="item-price">
            R$ 729,<span>00</span>
          </p>
          <p className="item-parcel">em até 12x consulte taxa</p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default ProductsArquivos
