import React from "react"

import SEO from "../components/seo"

import ProductsArquivos from "../components/products/produtos-arquivos"

const Arquivos = () => (
  <>
    <SEO title="Arquivos" />

    <ProductsArquivos />
  </>
)

export default Arquivos
